*, *:before, *:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    image-rendering: -webkit-optimize-contrast;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-text-size-adjust: 100%;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ul, ol {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button, html input[type=button], input[type=reset], input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer;
}

input:not([type='radio']):not([type='checkbox']), textarea, select {
    -webkit-appearance: none;
}

mark {
    background-color: transparent;
    font-weight: bold;
    color: inherit;
}

/* end reset */

/*reset*/
body {
    font-size: 1rem;
    line-height: 1.745rem;
    font-family: Helvetica, sans-serif;
    color: #1a1a1a;
}

.clearfix:before, .clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.clearfix {
    clear: both;
    overflow: hidden;
}


/*CUSTOM STYLES*/

html, body {
    /*font-size: 0.75rem;*/
}

body {
    /*background-color: #1E1E1E !important;*/
    /*color: #ffffff;*/
}

.d-flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-flex-end {
    justify-content: flex-end;
}

.mt-24 {
    margin-top: 1.5rem;
}

.mb-24 {
    margin-bottom: 1.5rem;
}

.mr-auto {
    margin-right: auto !important;
}

.p-16 {
    padding: 1rem;
}

.img-fluid {
    width: 100%;
    height: auto;
    display: block;
}

.w-100 {
    width: 100%;
}

/*LINKS*/
.links-primary {
    color: #ee220c;
}

.links-primary:hover {
    text-decoration: underline;
}

a.disabled {
    pointer-events: none;
}

/*!LINKS*/

/*LOGO*/
.header__logo-img__wrap.h2 {
    width: 3.75rem;
}

/*!LOGO*/

/*HEADER*/

.header-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.header-wrap .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
}

.header-item {
    flex: 1 1 0;
}

.header__menu-logo__wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.header__search__wrap .MuiInputBase-root,
.header__search__wrap .MuiInputBase-input {
    width: 100%;
}

.header__search__wrap {
    display: flex;
}

.header__profile__wrap {
    justify-content: flex-end;
}

.header__logo__wrap {
    display: flex;
    align-items: center;
    color: #ee220c;
    font-weight: bold;
}

.header__logo__wrap.MuiTypography-h4 {
    font-weight: bold;
}

.header__logo-img__wrap {
    display: inline-block;
    width: 1.875rem;
}

.header__logo-img__wrap img {
    width: 100%;
}

.header__logo-text__wrap {
    display: inline-block;
    margin-left: 0.625rem;
}

/* !HEADER*/


/*DRAWER*/

.drawer__wrap .MuiDrawer-paper {
    /*background-color: #303030;*/
    /*color: #ffffff;*/
}

.drawer__wrap .MuiListItemIcon-root {
    color: #ffffff;
}

.drawer__wrap .MuiDivider-root {
    background-color: rgba(255, 255, 255, 0.3);
}

.drawer__wrap .MuiListItem-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.drawer__wrap .MuiListItem-root.Mui-selected {
    background-color: rgba(255, 255, 255, 0.4);
}

.drawer__wrap .MuiButtonBase-root.MuiListItem-root.Mui-selected {
    background-color: rgba(255, 255, 255, 0.4);
}

.drawer__wrap .drawer__links {
    margin-top: auto;
}

.drawer__wrap .drawer__links-item {
    color: #ffffff;
}

.drawer__wrap .drawer__links-item:not(.Mui-selected):hover {
    text-decoration: underline;
}

.drawer__wrap .drawer__links-item.Mui-selected {
    color: #ee220c;
}

.drawer__wrap .drawer__links .MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background: none;
}


/* !DRAWER*/

/*SCROLLABLE TABS*/

.scrollable-tabs__wrap .MuiTab-root {
    color: #fff;
    border: none;
    height: 2rem;
    min-height: auto !important;
    display: inline-flex;
    outline: 0;
    margin: 0.75rem 0.625rem;
    padding: 0 1.25rem !important;
    min-width: auto !important;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    white-space: nowrap;
    border-radius: 1rem;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #404040;
    opacity: 0.8 !important;
}

.scrollable-tabs__wrap .Mui-selected {
    opacity: 1 !important;
    background-color: #949494;
}

.scrollable-tabs__wrap .MuiTabs-scroller {
    display: flex;
    align-items: center;
}

.scrollable-tabs__wrap .MuiTabs-indicator {
    display: none;
}

/* !SCROLLABLE TABS*/


/*COLLAPSE CARD*/

.card-header__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

/* !COLLAPSE CARD*/

/*WELCOME SECTION*/

.welcome-section__content {
    display: flex;
    align-items: flex-start;
}

.welcome-section__content__text {
    flex: 1;
    margin-right: 1.875rem;
}

.welcome-section__content__text .list__decimal {
    list-style: decimal;
    padding: 0 0 0 1rem;
}

.welcome-section__content__text .list__decimal span {
    margin-left: 5px;
    display: block;
}

.welcome-section__content__img {
    width: 31.25rem;
}

/* !WELCOME SECTION*/


/*USER CARD*/

.user-card__wrap .MuiAvatar-root {
    width: 3.75rem;
    height: 3.75rem;

}

.user-card__wrap .user-card__text {
    flex: 1;
    padding: 0 1rem;

}

.user-card__wrap .user-card__text {
    flex: 1;
    padding: 0 1rem;
}

.user-card__wrap .user-card__live-indicator {
    text-align: right;
}

.user-card__wrap .user-card__room-population-indicator {
    color: #ee220c;
    font-weight: bold;
    text-align: right;
    margin-right: 0.625rem;
}

.user-card__wrap .btn-shareable {
    min-width: 1.875rem;
}

.user-card__wrap .btn-shareable .btn__wrap {
    line-height: 1;
    min-width: auto;
    width: 1.25rem;
}

.user-card__wrap .btn-shareable.share {
    padding: 0 8px 0 2px;
}

.user-card__wrap .btn-shareable.share .btn__wrap {
    width: 2rem;
}

.user-card__wrap .btn-shareable svg {
    fill: #646464;
}

.user-card__wrap .user-card__actions {
    margin-left: auto;
}

.user-card__wrap .user-card__actions .MuiCardActions-root {
    padding: 0;
}

.user-card__wrap .btn-join:hover {
    background-color: #6ab7f5;
}

.user-card__wrap .user-card__tags ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.user-card__wrap .tag:not(:last-child) {
    margin-right: 0.625rem;
}

.user-card__item__container {
    justify-content: flex-end;
}

.user-card__wrap .user-card__item__container + .user-card__item__container {
    margin-top: 5px;
}

.user-card__item .user-card__item__title-name {
    max-height: 4.375rem;
    overflow: hidden;
}

/* !USER CARD*/

.collapse-section__wrap + .collapse-section__wrap {
    margin-top: 0.9375rem;
}

.collapse-section__wrap .MuiCardContent-root {
    padding: 0.625rem 1rem !important;
}


.picker__date__wrap .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
    position: absolute;
    right: 0;
}

.modal__set-topic {
    padding-bottom: 1.875rem !important;
}

.modal__set-topic__content__wrap {
    align-items: center;
}

.modal__set-topic .MuiTypography-h5 {
    margin-bottom: 1.25rem;
}

.modal__set-topic > .MuiTypography-body1 {
    margin-top: 1.875rem;
    margin-bottom: 0.9375rem;
}

.modal__set-topic__control-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*RESET MODALS*/
.MuiDialog-container {
    margin-top: 9.375rem !important;
    align-items: flex-start !important;
    height: auto !important;
}

/*!RESET MODALS*/


.modal__login {
    padding: 4.0625rem 1.5rem !important;
    text-align: center;
}

.modal__login .header__logo__wrap {
    display: inline-block;
}

.modal__login .sign {
    margin-top: 1.25rem;
}

.modal__login .InLogin__wrap {
    width: 70%;
    margin: 0 auto;
}

.modal__topic-feedback .header__logo__wrap {
    display: inline;
}

.modal__topic-feedback__textField {
    margin-top: 0.625rem;
    width: 100%;
}

.modal__topic-feedback__actions {
    margin-top: 3.125rem;
    display: flex;
    justify-content: flex-end;
}

/*LINKED IN LOGIN BUTTON*/
.InLogin__wrap {
    width: 100%;
}

.login__btn-linkedin {
    border: 1px solid transparent !important;
    padding: 0.5rem 1.5rem;
    font-size: 1.2rem !important;
    letter-spacing: .1rem !important;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    text-align: center;
    width: 100%;
    position: relative;
    display: inline-block;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.login__btn-linkedin:hover {
    color: #0077b5;
    border: 1px solid #0077b5 !important;
    background: #ffffff !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 0.625rem 0px rgba(0, 0, 0, 0.12);
    text-decoration: none !important;
}

.wrap-link__LI {
    margin: 3rem 0 1rem 0;
}

.login__btn-linkedin > svg {
    font-size: 2.5rem;
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
}

.CheckAuth_layer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CheckAuth_icon__scale {
    animation: icon-scale 1s ease-in-out infinite alternate;
}

@keyframes icon-scale {
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(3.0);
    }
}

.CheckAuth_icon__loop {
    animation: icon-loop 1s linear infinite;
    width: 5.5rem;
    height: 5.5rem;
}

.CheckAuth_icon__loop svg {
    font-size: 5.5rem;
}

@keyframes icon-loop {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

/*!LINKED IN LOGIN BUTTON*/

.separator {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.terms__logo-wrap {
    margin-top: 1.875rem;
    margin-bottom: 3.125rem;
}


/*PROFILE*/

.profile__wrap .MuiInput-input {
    font-size: 1.25rem;
}

.profile__wrap .profile__wrap__content-avatar {
    margin: 6.25rem auto 0;
    width: 12.5rem;
    height: 12.5rem;
}

.profile__wrap .profile__wrap__content-name {
    margin-top: 1.875rem;
    text-align: center;
}

.profile__wrap__content-info {
    margin-top: 3.75rem;
}

.profile__wrap__content-info > .MuiGrid-container + .MuiGrid-container {
    margin-top: 1.875rem;
}

.profile__wrap__content-info__desc {
    text-align: right;
}

/*!PROFILE*/


/*CONNECTIONS*/
.connections__list {
    margin-top: 1.25rem;
}

.connections__item + .connections__item {
    margin-top: 1.25rem;
}

/*!CONNECTIONS*/

/*TABLE*/


.table__custom .MuiTableCell-root {
    border: none;
}

.table__custom .fixed {
    table-layout: fixed;
}

.table__custom .MuiTableBody-root .MuiTableCell-root {
    padding: 1.5rem;
}

/*!TABLE*/


/*DASHBOARD Card*/
.card-dashboard > .MuiCardContent-root:last-child {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
}

.card-dashboard .modal__set-topic__content__wrap:nth-child(2) {
    align-items: flex-end;
}

.card-dashboard .MuiListItem-root {
    padding-top: 5px;
    padding-bottom: 5px;
}

.card-dashboard .modal__set-topic__content__wrap:nth-child(1) > .MuiGrid-item:nth-child(1) {
    padding: 1.25rem 2.5rem;
}

.card-dashboard .modal__set-topic__content__wrap:nth-child(1) > .MuiGrid-item:nth-child(2) {
    padding: 6px 2.5rem 1.875rem;
}

.card-dashboard .modal__set-topic__content__wrap:nth-child(2) .MuiGrid-item:nth-child(2) {
    padding-right: 1.5rem;
    padding-bottom: 1.25rem;
}

.card-dashboard + .card-dashboard {
    margin-top: 1rem;
}

.card-dashboard .DashboardCard-buttonPrimary-21:hover {
    background-color: #6ab7f5;
}

/*!DASHBOARD Card*/

/*DASHBOARD*/
.dashboard__wrap {
}

.dashboard__wrap__section {
}

.dashboard__wrap__section + .dashboard__wrap__section {
    margin-top: 1.5rem;
}

/*!DASHBOARD*/

/*MEETING*/
.fullScreen__components-centered {
    width: 100%;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.fullScreen__components-centered .MuiTypography-h2,
.fullScreen__components-centered .MuiTypography-h3 {
    align-items: center;
    margin-bottom: 9.375rem;
}

/*!MEETING*/


/*LIVE USERS LIST SEARCH*/

.org-attendee__search {
    padding-bottom: 1rem;
}

.list-attendee__title {
    color: #ffffff;
}

.list-attendee__text {
    color: #ee220c;
}

.org-attendee__search .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.org-attendee__search .PrivateNotchedOutline-root-10,
.org-attendee__search .MuiOutlinedInput-notchedOutline,
.org-attendee__search legend {
    border-color: rgba(154, 154, 154, 0.99) !important;
}

.org-attendee__search .MuiFormLabel-root,
.org-attendee__search .MuiFormLabel-root.Mui-focused,
.org-attendee__search input {
    color: rgba(154, 154, 154, 0.99);
}

.sort-alpha__attendees-list.MuiToggleButton-root {
    padding: 7px;
}

/*!LIVE USERS LIST SEARCH*/


/*LIVE USERS LIST*/

.user-li-list, .user-li-list .OrganizerAttendees {
    height: 100%;
}

.user-li-list .OrganizerAttendees {
    padding-right: 0;
    padding-left: 0;
}

.list-attendee__LI-btn {
    padding: 0.2rem;
    border-radius: 3px;
    display: flex;
    align-items: center;
    background: #0077b5;
    color: white;
}

.list-attendee__title.live {
    line-height: 1.2;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-attendee__LI-btn > .text {
    margin-left: 1rem;
}

.OrganizerAttendees > ul {
    padding: 0 1rem;
    overflow: auto;

}

.attendee .OrganizerAttendees > ul {
    height: 61vh;
}

.organizer .OrganizerAttendees > ul {
    height: 63vh;
}

.list-attendee__title {
    color: #ffffff;
}

/*!LIVE USERS LIST*/


/*THEME TOGGLE*/
.theme-toggle__wrap {
    margin-right: 0.9375rem;
    display: flex;
    align-items: center;
}

.theme-toggle__wrap label.MuiFormControlLabel-root {
    margin-right: 0;
    margin-left: 0;
}

/*!THEME TOGGLE*/


/*SHARE MENU*/
.menu-share .MuiList-root{
    display: flex;
    align-items: center;
}
.menu-share li {
    padding: 0;
}
.menu-share li .react-share__ShareButton {
    padding: 6px 1rem !important;
}
.menu-share li .react-share__ShareButton .MuiSvgIcon-root {
    vertical-align: middle;
}
/*!SHARE MENU*/
